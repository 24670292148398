






















































import { configureProgramConfirmation } from '@/popupcomponents/configureprogramconfirmation/configureProgramConfirmation';
export default configureProgramConfirmation;
