import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component
export class configureProgramConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public openCreatePanel:boolean = false;
    public  panelTitle:string = '';

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    closePopup() {
      this.$emit(APP_CONST.CLOSE);
      document.body.style.pointerEvents ='all';
    }
    inviteProgramAdmin(){
      this.$emit('inviteProgramAdmin');
    }

    goToConfigureProgram(){
      this.$emit('navigateToConfigure');
    }
}